<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                合同编号：
                            </td>
                            <td>
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入合同编号"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                公司名称：
                            </td>
                            <td>
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                销售人员：
                            </td>
                            <td>
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 100px;padding-top: 10px">
                                发货单编号：
                            </td>
                            <td style="padding-top: 10px">
                                <el-input v-model="searchItem.sendNoticeNum" size="small" placeholder="请输入发货编号"
                                ></el-input>
                            </td>
                            <td style="width: 80px;padding-top: 10px">
                                合同类型：
                            </td>
                            <td style="padding-top: 10px">
                                <el-select v-model="searchItem.contractType" size="small"
                                           style="width: 100%"
                                           placeholder="请选择合同类型">
                                    <el-option
                                            v-for="item in contractTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px; padding-top: 10px">
                                公司类型：
                            </td>
                            <td style="padding-top: 10px">
                                <el-select v-model="searchItem.companyType" size="small"
                                           style="width: 100%"
                                           placeholder="请选择公司类型">
                                    <el-option
                                            v-for="item in unitTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 90px;padding-top: 10px">
                                出库时间：
                            </td>
                            <td style="width: 28%;padding-top: 10px">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td></td>
                            <td></td>
                            <td style="padding-top: 10px;width: 300px ;text-align: center" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" style="margin-left: 30%"
                                           @click="reset">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="contractNum" width="180" label="合同编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractType" width="150" label="合同类型" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.contractType == 0">销售合同</span>
                                    <span v-else-if="scope.row.contractType == 1">技术服务合同</span>
                                    <span v-else-if="scope.row.contractType == 2">部件买卖合同</span>
                                    <span v-else-if="scope.row.contractType == 3">售后合同</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="userName" width="150" label="销售人员" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="sendNoticeNum" width="180" label="发货通知单编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="listNum" width="180" label="物料清单编号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyName" label="公司名称" align="left"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyType" width="150" label="公司类型" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-else-if="scope.row.companyType == 1">国企</span>
                                    <span v-else-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-else-if="scope.row.companyType == 3">代理商</span>
                                    <span v-else-if="scope.row.companyType == 4">集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="outTime" width="150" label="出库时间" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{changeDateFormat(scope.row.outTime)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="isOuting" width="150" label="出库状态" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.isOuting == 0" style="color: red">未出库</span>
                                    <span v-else-if="scope.row.isOuting ==1" style="color: green;">已出库</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" width="120" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="operation">
                                        <i class="iconfont iconbianji" title="编辑"
                                           v-if="hasAuthority('packing_detail_update') && scope.row.isOuting != 1"
                                           @click="editPackDetail(scope.row.id,scope.row.contractNum)"></i>
                                        <i class="iconfont iconxiangqing" title="详情"
                                           @click="showPackDetail(scope.row.id,scope.row.contractNum)"></i>
                                        <i class="iconfont icontongguo" title="出库"
                                           v-if="hasAuthority('packing_detail_sure') && scope.row.isOuting != 1"
                                           @click="submitPackDetail(scope.row.id)"></i>
                                        <!-- -->
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-form info">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    :close-on-click-modal="false"
                    width="50%"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-form-edit" ref="middle">
                    <table style="width: 98%;" v-for="(value, key) in table" :key="key">
                        <tr>
                            <td style="text-align: left;width: 120px">
                                <div class="text-show">{{key}}</div>
                            </td>
                            <td style="text-align: left">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="padding-bottom: 20px;padding-top: 10px">
                                <el-table
                                        :data="value"
                                        border
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: lineHeight}"
                                >
                                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="materialName" label="产品名称" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="outCount" label="数量" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="model" label="产品型号" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                </el-table>
                            </td>
                        </tr>
                    </table>
                    <div class="text-show" style="margin-bottom: 10px">售后仓库物料</div>
                    <el-table
                            ref="multipleTable"
                            :data="materialTable"
                            tooltip-effect="dark"
                            style="width: 100%"
                            max-height="400"
                            border
                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                            :cell-style="{padding:'0',height: lineHeight}"
                    >
                        <el-table-column type="index" width="50" align="center" label="序号"></el-table-column>
                        <!--                            <el-table-column type="selection" width="40" align="center"></el-table-column>-->
                        <el-table-column prop="materialCode" label="物料编码" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="materialName" label="产品名称" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <!--                            <el-table-column prop="useTypeName" label="适用机型" align="center"-->
                        <!--                                             show-overflow-tooltip></el-table-column>-->
                        <el-table-column prop="componentName" label="部件" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="model" label="型号" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="outCount" label="数量" align="center"
                                         show-overflow-tooltip></el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible= false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="dialog-form edit">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    :close-on-click-modal="false"
                    width="50%"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-form-edit" ref="middle">
                    <div class="add-tab">
                        <div class="tempBox">
                            使用模板：
                            <el-select 
                                v-model.trim="templateId" 
                                size="small" 
                                placeholder="选择对应物料清单的模板"
                                filterable>
                                <el-option 
                                    size="small" 
                                    v-for="item in templateList"
                                    :key="item.id" 
                                    :label="item.listNum"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <el-button style="margin-left:10px" type="primary" size="mini" @click="templateIdComfirm">确 定</el-button>
                        </div>
                        <el-button type="primary" size="mini" icon="el-icon-plus" @click="addRow">新增部件</el-button>
                        <el-button type="primary" size="mini" icon="el-icon-plus" @click="addAfterMaterial">新增售后物料</el-button>
                        <el-button type="primary" size="mini" icon="el-icon-plus" @click="addMainMaterial">新增物料信息</el-button>
                    </div>
                    <table style="width: 98%;" v-for="(value, key) in table" :key="key">
                        <tr>
                            <td style="text-align: left;width: 120px">
                                <div class="text-show">{{key}}</div>
                            </td>
                            <td style="text-align: left">
                                <el-button type="primary" size="mini" icon="el-icon-plus" style="margin-right: 12px"
                                           @click="addDetails(key,value)">新增物料
                                </el-button>
                                <el-button type="danger" size="mini" icon="el-icon-delete" style="margin-right: 12px"
                                           @click="deleteDetail(key)">删除此部件
                                </el-button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="padding-bottom: 20px;padding-top: 10px">
                                <el-table
                                        :data="value"
                                        border
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: lineHeight}"
                                >
                                    <el-table-column prop="materialCode" label="物料编码" align="center">
                                        <template slot-scope="scope">
                                            <el-select v-model="scope.row.materialCode" size="small" placeholder="请选择配置"
                                                       @change="chooseCode(scope.$index,value,scope.row.materialCode)"
                                                       filterable>
                                                <el-option size="small" v-for="itemObj in materialList"
                                                           v-if="itemObj.componentName ==  key"
                                                           :key="itemObj.materialCode" :label="itemObj.materialCode"
                                                           :value="itemObj.materialCode"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="materialName" label="产品名称" align="center"></el-table-column>
                                    <el-table-column prop="outCount" label="数量" align="center">
                                        <template slot-scope="scope">
                                            <el-input type="tel" size="small" v-model.number="scope.row.outCount"
                                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                                      placeholder="请输入数量"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="model" label="产品型号" align="center">
                                        <template slot-scope="scope">
                                            <el-select v-model="scope.row.model" size="small" placeholder="请选择配置"
                                                       @change="chooseModel(scope.$index,value,scope.row.model)"
                                                       filterable>
                                                <el-option size="small" v-for="(itemObj,index) in materialList"
                                                           v-if="itemObj.componentName ==  key"
                                                           :key="itemObj.id" :label="itemObj.model"
                                                           :value="itemObj.model"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="id" width="120" label="操作" align="center">
                                        <template slot-scope="scope">
                                            <i class="iconfont iconshanchu" style="color: red"
                                               @click="deleteDetails(scope.$index,value)"></i>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </td>
                        </tr>
                    </table>
                    <div v-if="materialTable">
                        <div class="text-show" style="margin-bottom: 10px">售后仓库物料</div>
                        <el-table
                                ref="multipleTable"
                                :data="materialTable"
                                tooltip-effect="dark"
                                style="width: 100%"
                                max-height="400"
                                border
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}"
                        >
                            <el-table-column type="index" width="50" align="center" label="序号"></el-table-column>
                            <!--                            <el-table-column type="selection" width="40" align="center"></el-table-column>-->
                            <el-table-column prop="materialCode" label="物料编码" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" align="center"
                                             show-overflow-tooltip></el-table-column>
<!--                            <el-table-column prop="useTypeName" label="适用机型" align="center"-->
<!--                                             show-overflow-tooltip></el-table-column>-->
                            <el-table-column prop="componentName" label="部件" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" label="型号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="outCount" label="数量" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-input type="tel" size="small"
                                              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                              v-model.number="scope.row.outCount"
                                              placeholder="请输入数量"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" width="50" label="操作" align="center">
                                <template slot-scope="scope">
                                    <i class="iconfont iconshanchu" style="color: red"
                                       @click="deleteMaterial(scope.$index)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="submit" :loading="isLoad">确 定</el-button>
                    <el-button type="primary" icon="el-icon-printer" @click="toPrint">打 印</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="edit-dialog">
            <el-dialog title="新增部件及物料"
                       :visible.sync="dialogTableVisible"
                       :close-on-click-modal="false"
                       center>
                <table style="margin-bottom: 10px">
                    <tr>
                        <td style="width: 80px">
                            新增部件：
                        </td>
                        <td>
                            <el-select v-model="component" size="small"
                                       style="width: 100%"
                                       filterable
                                       @change="getComponent"
                                       placeholder="请选择部件">
                                <el-option
                                        v-for="item in componentList"
                                        :key="item.wholeCode"
                                        :label="item.name"
                                        :value="item.wholeCode">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                </table>
                <el-table
                        ref="hehe"
                        :data="tableList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        max-height="400"
                        border
                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                        :cell-style="{padding:'0',height: lineHeight}"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="40" align="center"></el-table-column>
                    <!--                            <el-table-column type="selection" width="40" align="center"></el-table-column>-->
                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="materialName" label="产品名称" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="model" label="型号" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="outCount" label="数量" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input type="tel" size="small"
                                      v-model.number="scope.row.outCount" v-if="scope.row.checked == false"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      placeholder="请输入数量" disabled></el-input>
                            <el-input type="tel" size="small"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      v-model.number="scope.row.outCount" v-else
                                      placeholder="请输入数量"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelEdit">取 消</el-button>
                    <el-button type="primary" @click="submitEdit">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="edit-dialog">
            <el-dialog title="新增售后仓库物料"
                       :close-on-click-modal="false"
                       :visible.sync="materialDialogVisible"
                       center>
                <div style="display: flex;margin-bottom: 10px">
                    <div style="width: 80px;margin-top: 5px">
                        物料编码：
                    </div>
                    <div>
                        <el-input v-model="searchBasicItem.materialCode" size="small"
                                  placeholder="请输入物料编码"
                        ></el-input>
                    </div>
                    <div style="width: 80px;margin-top: 5px">
                        选择部件：
                    </div>
                    <div>
                        <el-select v-model="searchBasicItem.component" style="width: 100%"
                                   size="small"
                                   filterable placeholder="请选择部件名称">
                            <el-option
                                    v-for="(item,index) in componentNameList"
                                    :key="index"
                                    :label="item.componentName"
                                    :value="item.component">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="text-align: center;width: 200px">
                        <el-button type="primary" icon="el-icon-search" size="small"
                                   @click="searchBasicBtn">搜索
                        </el-button>
                        <el-button type="primary" icon="el-icon-refresh" size="small"
                                   @click="resetBasicBtn">重置
                        </el-button>
                    </div>
                </div>
                <el-table
                        ref="multipleMaterialTable"
                        :data="tableAfterList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        max-height="400"
                        border
                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                        :cell-style="{padding:'0',height: lineHeight}"
                        @selection-change="handleSelectionChangeAfter"
                >
                    <el-table-column type="selection" width="40" align="center"></el-table-column>
                    <!--                            <el-table-column type="selection" width="40" align="center"></el-table-column>-->
                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="materialName" label="产品名称" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="componentName" label="部件" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="model" label="型号" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="outCount" label="数量" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input type="tel" size="small"
                                      v-model.number="scope.row.outCount" v-if="scope.row.checked == false"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      placeholder="请输入数量" disabled></el-input>
                            <el-input type="tel" size="small"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      v-model.number="scope.row.outCount" v-else
                                      placeholder="请输入数量"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="materialDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitMaterial">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <el-dialog
                title="选择物料信息"
                :visible.sync="materialVisible"
                :close-on-click-modal="false"
                width="50%"
                center>
            <div style="display: flex;margin-bottom: 10px">
                <div style="width: 80px;margin-top: 5px">
                    物料编码：
                </div>
                <div>
                    <el-input v-model="searchMaterialItem.materialCode" size="small"
                              placeholder="请输入物料编码"
                    ></el-input>
                </div>
                <div style="width: 80px;margin-top: 5px">
                    选择部件：
                </div>
                <div>
                    <el-select v-model="searchMaterialItem.component" style="width: 100%"
                               size="small"
                               filterable placeholder="请选择部件名称">
                        <el-option
                                v-for="(item,index) in componentNameList"
                                :key="index"
                                :label="item.componentName"
                                :value="item.component">
                        </el-option>
                    </el-select>
                </div>
                <div style="text-align: center;width: 200px">
                    <el-button type="primary" icon="el-icon-search" size="small"
                               @click="searchMaterialBtn">搜索
                    </el-button>
                    <el-button type="primary" icon="el-icon-refresh" size="small"
                               @click="resetMaterialBtn">重置
                    </el-button>
                </div>
            </div>
            <div>
                <el-table
                        ref="mainMaterialTable"
                        :data="mainMaterialList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        max-height="400"
                        border
                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                        :cell-style="{padding:'0',height: lineHeight}"
                        @selection-change="handleSelectionChangeMaterial"
                >
                    <el-table-column type="selection" width="40" align="center"></el-table-column>
                    <!--                            <el-table-column type="selection" width="40" align="center"></el-table-column>-->
                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="materialName" label="产品名称" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="componentName" label="部件" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="model" label="型号" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="outCount" label="数量" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input type="tel" size="small"
                                      v-model.number="scope.row.outCount" v-if="scope.row.checked == false"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      placeholder="请输入数量" disabled></el-input>
                            <el-input type="tel" size="small"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      v-model.number="scope.row.outCount" v-else
                                      placeholder="请输入数量"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="mainCancel">取 消</el-button>
                <el-button type="primary" @click="mainSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                templateId:'',
                templateList:[],
                contractNum:'',
                pageList: [14, 20, 30, 50],
                tableHeight: '',
                component: '',
                tableData: [],
                materialList: [],
                afterMaterials: [],
                tableAfterList: [],
                mainMaterialList: [],
                selectLists: "",
                tableAfterListStr: "",
                table: [],
                dialogTableVisible: false,
                materialDialogVisible: false,
                materialVisible: false,
                tableList: [],
                componentNameList: [],
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                searchBasicItem: {
                    materialCode: '',
                    component: '',
                },
                searchMaterialItem: {
                    materialCode: '',
                    component: '',
                },
                contractTypes: [
                    {
                        label: '销售合同',
                        value: 0
                    },
                    {
                        label: '技术服务合同',
                        value: 1
                    },
                    {
                        label: '部件买卖合同',
                        value: 2
                    },
                    {
                        label: '售后合同',
                        value: 3
                    },
                ],
                unitTypes: [
                    {
                        label: '建机厂',
                        value: 0
                    },
                    {
                        label: '国企',
                        value: 1
                    },
                    {
                        label: '租赁商',
                        value: 2
                    },
                    {
                        label: '代理商',
                        value: 3
                    },
                    {
                        label: '集团公司',
                        value: 4
                    },
                ],
                saleList: [],
                tableListStr:'',
                multipleSelection: [],
                searchItem: {
                    contractNum: '',
                    companyName: '',
                    userName: '',
                    sendNoticeNum: '',
                    contractType: '',
                    companyType: '',
                    startDate: '',
                    endDate: '',
                    pageSize: 14,
                    pageIndex: 1,
                },
                totalPage: 10,
                editDialogVisible: false,
                infoDialogVisible: false,
                isLoad: false,
                componentList: [],
                materialTable: [],
                lineHeight: '',
                editTitle: '编辑物料清单',
                showTitle: '',
                id: '',
                hasSelectList:[],
            };
        },
        methods: {
            templateIdComfirm(){
                if(this.templateId == '') return
                this.$api.getPackingDetail({id: this.templateId}).then(res => {
                    if (res.code == 200) {
                        this.table = res.data.main;
                        this.materialTable = res.data.afterSales;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.pagePackDetail(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pagePackDetail(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    contractNum: '',
                    companyName: '',
                    userName: '',
                    sendNoticeNum: '',
                    contractType: '',
                    companyType: '',
                    startDate: '',
                    endDate: '',
                    pageSize: 14,
                    pageIndex: 1,
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            cancel() {
                this.editDialogVisible = false;
            },
            submit() {
                var mainOutDetailStr = '';
                var afterSalesDetailStr = '';
                this.isLoad = true;
                if(this.table){
                    for (var i in this.table) {
                        for (var item of this.table[i]) {
                            if (item.outCount == undefined || item.outCount == 0 || item.outCount == null) {
                                this.$message.error('物料清单中的物料不能为空或者是0');
                                this.isLoad = false;
                                return;
                            }
                            var flag = this.getRepeatItem(this.table[i]);
                            if (flag) {
                                this.$message.error('物料清单中的物料存在重复录入情况，请仔细查阅');
                                this.isLoad = false;
                                return;
                            }
                            if (mainOutDetailStr == '') {
                                mainOutDetailStr = item.id + ',' + item.outCount+ ',' + "0"
                            } else {
                                mainOutDetailStr = mainOutDetailStr + '|' + item.id + ',' + item.outCount+ ',' + "0"
                            }
                        }
                    }
                }
                if(this.materialTable){
                    for (var item of this.materialTable) {
                        if (afterSalesDetailStr == '') {
                            afterSalesDetailStr = item.id + ',' + item.outCount+ ',' + "3"
                        } else {
                            afterSalesDetailStr = afterSalesDetailStr + '|' + item.id + ',' + item.outCount+ ',' + "3"
                        }
                    }
                }
                if (!mainOutDetailStr && !afterSalesDetailStr){
                    this.$message.error('物料清单的物料信息不能为空，如误操作请点击"取消"按钮');
                    this.isLoad = false;
                    return;
                }
                this.$api.updatePackDetail({id: this.id, mainOutDetailStr: mainOutDetailStr,afterSalesDetailStr:afterSalesDetailStr}).then(res => {
                    if (res.code == 200) {
                        this.pageChange("refresh");
                        this.$message.success(res.message);
                        this.editDialogVisible = false;
                        this.isLoad = false;
                    } else {
                        this.$message.error(res.message);
                        this.isLoad = false;
                    }
                })
            },
            toPrint(){
                let tempArr = [];
                for (const key in this.table) {
                    this.table[key].map(item=>{
                        tempArr.push({
                            id:item.id,
                            materialCode:item.materialCode,
                            materialName:item.materialName,
                            outCount:item.outCount,
                            model:item.model,
                        })
                    })
                }
                let tempObj = {data:tempArr,contractNum:this.contractNum}
                localStorage.setItem('materialData',JSON.stringify(tempObj));
                window.open('/MaterialPrint')
            },
            cancelEdit() {
                this.dialogTableVisible = false;
                this.component = '';
                this.tableList = [];
            },
            editPackDetail(id, contractNum) {
                this.isLoad = false;
                this.materialTable = [];
                this.contractNum = contractNum;
                this.editTitle = '编辑《' + contractNum + '》物料清单'
                this.$api.getPackingDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.editDialogVisible = true;
                        this.table = res.data.main;
                        this.materialTable = res.data.afterSales;
                    } else {
                        this.$message.error(res.message)
                    }
                })
                this.id = id;
                this.getMaterialList();
                this.$api.listnumlist({id}).then(res=>{
                    this.templateId = '';
                    if(res.code == 200 && res.data.length >= 1){
                        this.templateList = res.data.filter(item=>{
                            return item.listNum != null;
                        })
                    }
                })
            },
            addAfterMaterial() {
                this.materialDialogVisible = true;
                var componentNames = []
                this.$api.getMaterialList({warehouseType: 3}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            var componentObj = {};
                            componentObj.component = item.component;
                            componentObj.componentName = item.componentName;
                            componentNames.push(componentObj)
                            if(this.materialTable){
                                if (JSON.stringify(this.materialTable).indexOf(item.id) != -1){
                                    item.checked = true;
                                }else {
                                    item.checked = false;
                                }
                            }else {
                                item.checked = false;
                            }
                        }
                        var checkedArr = []
                        for (const item of res.data) {
                            if (this.materialTable){
                                for (var itemObj of this.materialTable) {
                                    if (item.id == itemObj.id){
                                        item.outCount = itemObj.outCount;
                                    }
                                }
                            }
                            if (item.checked) {
                                checkedArr.push(item)
                            }
                        }
                        var newArr = []
                        for (var item of checkedArr) {
                            newArr.push(item);
                        }
                        for (var item of res.data) {
                            newArr.push(item);
                        }
                        this.tableAfterList = this.uniqueId(newArr);
                        this.tableAfterListStr = JSON.stringify(this.uniqueId(newArr));

                        this.componentNameList = this.uniqueCom(componentNames);
                        this.toggleSelection(checkedArr)
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            searchBasicBtn() {
                var newArr = [];
                var checkList = [];
                var materials = JSON.parse(this.tableAfterListStr)
                for (const item of this.mainMaterialList) {
                    if (item.checked){
                        checkList.push(item)
                    }
                }
                for (var item of materials) {
                    checkList.push(item)
                }
                this.tableAfterListStr = JSON.stringify(this.uniqueId(checkList));
                for (var item of this.uniqueId(checkList)) {
                    if (this.searchBasicItem.materialCode != '' && this.searchBasicItem.materialCode != undefined
                        && this.searchBasicItem.component != '' && this.searchBasicItem.component != undefined) {
                        if (item.component.indexOf(this.searchBasicItem.component) > -1) {
                            if (item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) {
                                newArr.push(item)
                            }
                        }
                    } else if (this.searchBasicItem.materialCode != '' && this.searchBasicItem.materialCode != undefined) {
                        if (item.materialCode.indexOf(this.searchBasicItem.materialCode) > -1) {
                            newArr.push(item)
                        }
                    } else if (this.searchBasicItem.component != '' && this.searchBasicItem.component != undefined) {
                        if (item.component.indexOf(this.searchBasicItem.component) > -1) {
                            newArr.push(item)
                        }
                    }else {
                        newArr.push(item)
                    }
                }
                this.tableAfterList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(newArr)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr)
            },
            resetBasicBtn() {
                var allArr = JSON.parse(this.tableAfterListStr);
                this.searchBasicItem = {
                    materialCode: '',
                    component: '',
                }
                var newArr = [];
                for (var item of this.tableAfterList) {
                    if (item.checked){
                        newArr.push(item)
                    }
                }
                for (var item of allArr) {
                    if (item.checked){
                        newArr.push(item)
                    }
                }
                for (const allArrElement of allArr) {
                    newArr.push(allArrElement)
                }
                this.tableAfterList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(this.tableAfterList)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr)
            },
            toggleSelection(rows) {
                if (rows) {
                    this.$nextTick(function () {
                        for (var row of rows) {
                            this.$refs.multipleMaterialTable.toggleRowSelection(row, true)
                        }
                    })
                }
            },
            submitMaterial() {
                var newArr = [];
                if (this.tableAfterList.length < 1) {
                    this.$message.error('所选新增的部件物料不能为空')
                    return;
                }
                for (var item of this.tableAfterList) {
                    if (item.checked) {
                        if (item.outCount < 1 || item.outCount == '') {
                            this.$message.error('所选新增的部件物料数量不能为空或者0')
                            return;
                        }
                        newArr.push(item);
                    }
                }

                this.materialTable = newArr;
                this.materialDialogVisible = false;
                this.toBottom()
            },
            deleteMaterial(index){
                this.materialTable.splice(index, 1)
            },
            getMaterialList() {
                //获取主物料仓库信息
                this.$api.getMaterialList({warehouseType: 0}).then(res => {
                    if (res.code == 200) {
                        this.materialList = res.data;
                        for (var item of this.materialList) {
                            item.copyModel = item.model;
                        }
                        this.selectLists = JSON.stringify(this.materialList);
                    }
                })
            },
            showPackDetail(id, contractNum) {
                this.showTitle = '编辑《' + contractNum + '》物料清单详情';
                this.$api.getPackingDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.infoDialogVisible = true;
                        this.table = res.data.main;
                        this.materialTable = res.data.afterSales;
                    }
                })
            },
            submitPackDetail(id) {
                this.$confirm('你确定要出库该物料清单吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.surePackDetail({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message);
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                }).catch(() => {
                })
            },
            //新增部件的提交
            submitEdit() {
                var newArr = [];
                var newObj = {};
                var name = '';
                if (this.tableList.length < 1) {
                    this.$message.error('所选新增的部件物料不能为空')
                    return;
                }
                for (var item of this.tableList) {
                    if (item.checked == true) {
                        if (item.outCount < 1 || item.outCount == '') {
                            this.$message.error('所选新增的部件物料数量不能为空或者0')
                            return;
                        }
                        newArr.push(item);
                    }
                }
                for (var item of this.componentList) {
                    if (item.wholeCode == this.component) {
                        name = item.name;
                    }
                }
                newObj[name] = newArr;
                
                let flag = false;
                for (const key in this.table) {
                    if(name == key){
                        flag = true;
                    }
                }
                if(flag){
                    this.table[name] = newArr
                }else{
                    Object.assign(this.table, newObj);
                    this.toBottom()
                }
                this.dialogTableVisible = false;
            },
            changeDateFormat(date) {
                if (date == null || date == '' || date == undefined) {
                    return '';
                } else {
                    let dt = new Date(date)
                    var year = dt.getFullYear();
                    var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                    var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                    return year + '-' + month + '-' + date
                }
            },
            deleteDetails(index, value) {
                value.splice(index, 1)
            },
            addDetails(key, value) {
                value.push({})
            },
            deleteDetail(key) {
                delete this.table[key];
                this.$forceUpdate();
            },
            chooseModel(index, value, val) {
                //var newArr = value;
                var temp = JSON.parse(this.selectLists);
                for (var item of temp) {
                    if (val == item.model) {
                        value.splice(index, 1, item)
                        this.$forceUpdate();
                    }
                }
                this.materialList = JSON.parse(this.selectLists)
            },
            chooseCode(index, value, val) {
                var temp = JSON.parse(this.selectLists);
                for (var item of temp) {
                    if (item.materialCode == val) {
                        value.splice(index, 1, item)
                        this.$forceUpdate();
                    }
                }
                this.materialList = JSON.parse(this.selectLists)
            },
            addRow() {
                this.dialogTableVisible = true;
                this.$api.getDictionaryData().then(res => {
                    if (res.code == 200) {
                        this.componentList = res.data.componentList;
                    }
                })
                this.toBottom()
            },
            handleSelectionChange(val) {
                var flag = false;
                if (this.multipleSelection.length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.tableList) {
                        item.checked = false;
                    }
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            handleSelectionChangeAfter(val) {
                var flag = false;
                if (this.multipleSelection.length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.tableAfterList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.tableAfterList) {
                        item.checked = false;
                    }
                    for (var item of this.tableAfterList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            getComponent(val) {
                this.tableList = [];
                for (var item of this.materialList) {
                    if (item.component == val) {
                        item.checked = false;
                        this.tableList.push(item);
                    }
                };
                let name = '';
                this.componentList.map(item=>{
                    if(item.wholeCode == val){
                        name = item.name
                    }
                })
                let arr = this.table[name] || [];
                this.tableList.map(item1=>{
                    arr.map(item2=>{
                        if(item1.id == item2.id){
                            item1.checked = true;
                            item1.outCount = item2.outCount
                        }
                    })
                })
                this.$nextTick(()=>{
                    this.tableList.map(item=>{
                        if(item.checked){
                            this.$refs.hehe.toggleRowSelection(item,true)
                        }
                    })
                })
                
            },
            getRepeatItem(arr) {
                for (var i = 0; i < arr.length; i++) {//遍历从0开始的数组
                    for (var j = i + 1; j < arr.length; j++) {//遍历从1开始的数组
                        if (arr[i] === arr[j]) {//判断前一个值与后一个值全等
                            return true;
                        }
                    }
                }
            },
            toBottom() {
                this.$nextTick(() => {
                    let middle = this.$refs["middle"];
                    middle.scrollTop = middle.scrollHeight;
                })
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            uniqueCom(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].component]) {
                        newArr.push(arr[i]);
                        obj[arr[i].component] = true;
                    }
                }
                return newArr;
            },
            uniqueId(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].id]) {
                        newArr.push(arr[i]);
                        obj[arr[i].id] = true;
                    }
                }
                return newArr;
            },
            addMainMaterial(){
                this.materialVisible = true;
                this.multipleSelection = []
                var componentNames = [];
                this.$api.getMaterialList({warehouseType: 0}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            item.checked = false;
                        }
                        if (res.code == 200) {
                            for (var item of res.data) {
                                var componentObj = {};
                                componentObj.component = item.component;
                                componentObj.componentName = item.componentName;
                                componentNames.push(componentObj)
                            }
                            var checkedArr = []
                            for (var item of res.data) {
                                if (JSON.stringify(this.table).indexOf(item.materialCode) != -1) {
                                    item.checked = true;
                                    checkedArr.push(item);
                                }
                            }
                            for (var item of checkedArr) {
                                for (var itemElement of this.table[item.componentName]) {
                                    if (item.materialCode == itemElement.materialCode){
                                        item.outCount = itemElement.outCount;
                                    }
                                }
                            }
                            var newArr = []
                            for (var item of checkedArr) {
                                newArr.push(item)
                            }
                            for (var item of res.data) {
                                newArr.push(item);
                            }
                            this.mainMaterialList = this.uniqueId(newArr);
                            this.mainMaterialListStr = JSON.stringify(this.uniqueId(newArr));
                            this.componentNameList = this.uniqueCom(componentNames);
                            this.toggleSelectionMaterial(checkedArr)
                        }
                    }
                })
            },
            handleSelectionChangeMaterial(val) {
                var flag = false;
                if (this.multipleSelection.length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.mainMaterialList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.mainMaterialList) {
                        item.checked = false;
                    }
                    for (var item of this.mainMaterialList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            searchMaterialBtn() {
                var newArr = [];
                var checkList = [];
                var materials = JSON.parse(this.mainMaterialListStr)
                for (const item of this.mainMaterialList) {
                    if (item.checked){
                        checkList.push(item)
                    }
                }
                for (var item of materials) {
                    checkList.push(item)
                }
                this.mainMaterialListStr = JSON.stringify(this.uniqueId(checkList));
                for (var item of this.uniqueId(checkList)) {
                    if (this.searchMaterialItem.materialCode != '' && this.searchMaterialItem.materialCode != undefined
                        && this.searchMaterialItem.component != '' && this.searchMaterialItem.component != undefined) {
                        if (item.component.indexOf(this.searchMaterialItem.component) > -1) {
                            if (item.materialCode.indexOf(this.searchMaterialItem.materialCode) > -1) {
                                newArr.push(item)
                            }
                        }
                    } else if (this.searchMaterialItem.materialCode != '' && this.searchMaterialItem.materialCode != undefined) {
                        if (item.materialCode.indexOf(this.searchMaterialItem.materialCode) > -1) {
                            newArr.push(item)
                        }
                    } else if (this.searchMaterialItem.component != '' && this.searchMaterialItem.component != undefined) {
                        if (item.component.indexOf(this.searchMaterialItem.component) > -1) {
                            newArr.push(item)
                        }
                    } else {
                        newArr.push(item)
                    }
                }
                this.mainMaterialList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(newArr)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelectionMaterial(checkedArr);
            },
            resetMaterialBtn() {
                var allArr = JSON.parse(this.mainMaterialListStr);
                this.searchMaterialItem = {
                    materialCode: '',
                    component: '',
                }
                var newArr = [];
                for (var item of this.mainMaterialList) {
                    if (item.checked){
                        newArr.push(item)
                    }
                }
                for (var item of allArr) {
                    if (item.checked){
                        newArr.push(item)
                    }
                }
                for (const allArrElement of allArr) {
                    newArr.push(allArrElement)
                }
                this.mainMaterialList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(this.mainMaterialList)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelectionMaterial(checkedArr)
            },
            toggleSelectionMaterial(rows) {
                if (rows) {
                    this.$nextTick(function () {
                        for (var row of rows) {
                            this.$refs.mainMaterialTable.toggleRowSelection(row, true)
                        }
                    })
                }
            },
            mainCancel() {
                this.materialVisible = false;
            },
            mainSubmit() {
                this.resetMaterialBtn()
                var newArr = [];
                var newObj = {};
                var name = '';
                if (this.mainMaterialList.length < 1) {
                    this.$message.error('所选新增的物料信息不能为空')
                    return;
                }
                for (var item of this.mainMaterialList) {
                    if (item.checked == true) {
                        if (item.outCount < 1 || item.outCount == '') {
                            this.$message.error('所选新增的部件物料数量不能为空或者0')
                            return;
                        }
                        newArr.push(item);
                    }
                }
                for (var item of newArr) {
                    if (item.componentName in newObj) {
                        for (const key in newObj) {
                            if (item.componentName == key) {
                                newObj[key].push(item);
                            } else {
                                continue;
                            }
                        }
                    } else {
                        var otherArr = []
                        otherArr.push(item)
                        newObj[item.componentName] = otherArr;
                    }

                }
                this.table = newObj
                this.materialVisible = false;
                this.toBottom()
            },
        },
        created() {
            this.pageChange(1);
            this.$api.getSaleList().then(res => {
                if (res.code == 200) {
                    this.saleList = res.data
                }
            })
            this.tableHeight = (window.innerHeight) * 0.66
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    }
    ;
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        margin-right: 15%;
        color: #20a0ff;
    }

    .icontongguo {
        margin-left: 15%;
        color: green;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 140px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .operation {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .dialog-form-edit {
        max-height: 650px;
        overflow-y: auto;
    }

    .text-show {
        color: #20a0ff;
        text-align: center;
        font-size: 20px;
        font-family: '微软雅黑';
    }

    .add-tab {
        text-align: right;
        margin: 0 0 10px 0;
        position: relative;
        height: 40px;
        .tempBox{
            position: absolute;
            
        }
    }
</style>